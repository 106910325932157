// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: `AIzaSyBLBU1eVuVZdoCBZLi2HSOhDzGtYG5jlMQ`,
    authDomain: `ezeikel-pemberton-photography.firebaseapp.com`,
    databaseURL: `https://ezeikel-pemberton-photography.firebaseio.com`,
    projectId: `ezeikel-pemberton-photography`,
    storageBucket: `ezeikel-pemberton-photography.appspot.com`,
    messagingSenderId: `550055016794`,
  },
  contentful: {
    spaceId: `gjba64va93uk`,
    token: `oV8V1p_z8ycmTbYnnd47JfSAKzAlLc4pJ4-R4psRpOk`,
  },
};
