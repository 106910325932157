import { Component, OnInit } from "@angular/core";

@Component({
  selector: `ep-photography-contact`,
  templateUrl: `./contact.component.html`,
  styleUrls: [`./contact.component.scss`],
})
export class ContactComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
