import { Component, OnInit } from "@angular/core";

@Component({
  selector: `ep-photography-scroll-down`,
  templateUrl: `./scroll-down.component.html`,
  styleUrls: [`./scroll-down.component.scss`],
})
export class ScrollDownComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
