import { Component, OnInit } from "@angular/core";

@Component({
  selector: `ep-photography-blog`,
  templateUrl: `./blog.component.html`,
  styleUrls: [`./blog.component.scss`],
})
export class BlogComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
