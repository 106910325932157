import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule, Routes } from "@angular/router";

import { HomeComponent } from "./home/home.component";
import { BlogComponent } from "./blog/blog.component";
import { BlogPostComponent } from "./blog-post/blog-post.component";

const routes: Routes = [
  { path: `home`, component: HomeComponent },
  { path: `blog`, component: BlogComponent },
  { path: `blog/:post`, component: BlogPostComponent },
  {
    path: ``,
    redirectTo: `/home`,
    pathMatch: `full`,
  },
  { path: `**`, component: HomeComponent }, // TODO: Replace with PageNotFoundComponent
];

@NgModule({
  imports: [CommonModule, RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
